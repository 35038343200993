<template>
  <div class="panel-page_container">
    <div class="panel-page_header">
      <el-button type="info" class="fa fa-refresh" @click="getList"></el-button>
      <el-button class="" type="primary" @click="addHandle">添加</el-button>
    </div>
    <div class="panel-page_main">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-background="transparent"
        @row-dblclick="editHandle"
        row-key="id"
      >
        <el-table-column
          prop="id"
          label="ID"
          min-width="40"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="打印机名称"
          min-width="120"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="brandId"
          label="品牌"
          min-width="60"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="brandId"
          label="品牌"
          min-width="60"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <span>{{ getBrandName(scope.row) }}</span>
          </template>
          </el-table-column>
        <el-table-column
          prop="deviceId"
          label="设备号"
          min-width="60"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deviceSecret"
          label="设备秘钥"
          min-width="60"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="card"
          label="Gprs卡号"
          min-width="60"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="number"
          label="打印张数"
          min-width="80"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          min-width="90"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <span class="fa fa-circle" :style="{ color: PRINT_STATUS[scope.row.status].color }">{{ " " + PRINT_STATUS[scope.row.status].label }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="enable"
          label="使用"
          min-width="80"
          header-align="center"
          align="center"
        >
        <template v-slot="scope">
            <el-switch
              v-model="scope.row.enable"
              active-color="#18bc9c"
              inactive-color="#d2d6de"
              :active-value=1
              :inactive-value=0
              @change="toggleHandle(scope.row, 'enable')"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="创建时间"
          min-width="120"
          header-align="center"
          align="center"
          :formatter="
            (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
          "
        >
        </el-table-column>
        <el-table-column
          prop="updatetime"
          label="更新时间"
          min-width="120"
          header-align="center"
          align="center"
          :formatter="
            (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
          "
        >
        </el-table-column>
        <el-table-column label="操作" header-align="center" align="center" min-width="120">
          <template v-slot="scope">
            <el-button
              class="icon-btn fa fa-refresh"
              type="primary"
              @click="refreshHandle(scope.row)"
            > 
            </el-button>
            <el-button
              class="icon-btn fa fa-pencil"
              type="primary"
              @click="editHandle(scope.row)"
            >
              <!-- 编辑 -->
            </el-button>
            <el-button
              class="icon-btn fa fa-trash"
              type="danger"
              @click="delHandle(scope.row)"
            >
              <!-- 删除 -->
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { PRINT_STATUS, PRINT_BRAND_OPTIONS,PRINT_BRAND } from "@/helpers/constants";
import { msgErr, msgSuc } from "@/helpers/message";
import {
  listByPage,
  deleteById,
  toggleById,
  terminalStatus,
} from "@/apis/uniprint/printer";
import Sortable from "sortablejs";
const calls = [];
export default {
  props: {
    brandId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      PRINT_STATUS: PRINT_STATUS,
      PRINT_BRAND_OPTIONS:PRINT_BRAND_OPTIONS,
      PRINT_BRAND:PRINT_BRAND,
      tableData: [],
      loading: false,
      initForm: {},
      form: {},
      paginationData: {},
    };
  },
  emits: ["add", "edit"],
  created() {
    console.log(calls);
  },
  mounted() {},
  methods: {
    editHandle(row) {
      this.$emit("edit", row);
    },
    getList() {
      this.loading = true;
      listByPage({brandId:this.brandId==="all"?"":this.brandId,...this.paginationData})
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addHandle() {
      this.$emit("add");
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },

    refreshHandle(row) {
      terminalStatus(row.deviceId).then(({ code, message }) => {
        if (code === "0000") {
          msgSuc("操作成功！");
          this.getList();
        } else {
          msgErr(message);
        }
      });
    },

    toggleHandle(row, key) {
      toggleById(row, key)
        .then(({ code, message  }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        })
    },
    getBrandName(row){
      let obj = PRINT_BRAND[row.brandId];
      if(obj){
        return obj.label;
      }else{
        return "";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-page_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .panel-page_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
.move-handle {
  background-color: #2c3e50;
  color: #ffffff;
}
</style>
