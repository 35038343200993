import http from "@/helpers/http";
import { pack } from "@/helpers/utils";
const service = "faUniprintPrinter/";

export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}

export function save(form) {
	return http.request({
		url: service + "save",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function updateById(form) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function toggleById(form, key) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		data: {
			body: {
				id: form.id,
				[key]: form[key],
			},
		},
	});
}


export function terminalStatus(code) {
	return http.request({
		url: "yly/terminalStatus",
		method: "POST",
		params: {
			machine_code: code
		},
		data: {},
	});
}